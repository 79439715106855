import React from 'react';
import { SECTIONS } from '../../constants';
import * as Styled from './slpFFHeaderImageStyles';

export default function slpFFHeaderImage({ sectionData }) {
  const DesktopData = sectionData?.assets?.filter((section) => {
    return section?.title === SECTIONS.SLP.FREE_FILL.TITLE_DESKTOP;
  })[0];
  const MobileData = sectionData?.assets?.filter((section) => {
    return section?.title === SECTIONS.SLP.FREE_FILL.TITLE_MOBILE;
  })[0];

  return (
    <Styled.TitleContainer $bgImage={sectionData?.image?.file?.url}>
      <Styled.Title>
        <Styled.TitleDesktopImage src={DesktopData?.file?.url} />
        <Styled.TitleMobileImage src={MobileData?.file?.url} />
      </Styled.Title>
    </Styled.TitleContainer>
  );
}
